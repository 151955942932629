import React from 'react';
import { Message } from '@/types/Message';
import { MessageAvatar } from './MessageAvatar';
import { MessageContent } from './MessageContent';
import { MessageActions } from './MessageActions';

interface MessageItemProps {
  message: Message;
  handleArchive: (id: string) => void;
  handleCreateClient: (email: string) => void;
}

export const MessageItem: React.FC<MessageItemProps> = ({ message, handleArchive, handleCreateClient }) => {
  return (
    <div className='flex items-center justify-between px-6 py-4'>
      <div className='flex items-center gap-4'>
        <MessageAvatar email={message.email} avatar={message.avatar} />
        <MessageContent email={message.email} content={message.content} />
      </div>
      <MessageActions
        messageId={message.id}
        email={message.email}
        isNew={message.isNew}
        handleArchive={handleArchive}
        handleCreateClient={handleCreateClient}
      />
    </div>
  );
};
