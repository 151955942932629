import React from 'react';
import { Table, TableHeader, TableRow, TableHead, TableBody } from '@/components/ui/table';
import { PostTableRow } from './PostTableRow';
import { Post } from '@/types/Post';

interface PostTableProps {
  posts: Post[];
  handleEditPost: (post: Post) => void;
}

export const PostTable: React.FC<PostTableProps> = ({ posts, handleEditPost }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Title</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {posts.map((post) => (
          <PostTableRow key={post.id} post={post} handleEditPost={handleEditPost} />
        ))}
      </TableBody>
    </Table>
  );
};
