'use client';
import { useEffect, useCallback } from 'react';
import useLoginStore from '@/stores/useLoginStore';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';

// Custom hook to check if the user is logged in
export function useIsUserLoggedIn() {
  const { isLoggedIn, checkLoginStatus } = useLoginStore();

  useEffect(() => {
    // Check the token immediately
    checkLoginStatus();

    const intervalId = setInterval(checkLoginStatus, 200);

    return () => clearInterval(intervalId);
  }, [checkLoginStatus]);

  return isLoggedIn;
}

export function useClearSession() {
  const router = useRouter();

  const clearSession = useCallback(async () => {
    await signOut({ redirect: false });
    router.push('/');
  }, [router]);

  return clearSession;
}

export default useIsUserLoggedIn;
