'use client';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MessagesDrawer } from '@/components/shared/usermenu/MessagesDrawer/MessagesDrawer';
import { Button } from '@/components/ui/button';
import { useClearSession } from '@/hooks/useIsUserLoggedIn';
import { User } from '@/types/User';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { SettingsPanel } from './SettingsPanel/SettingsPanel';
import { Badge } from '@/components/ui/badge';
import { useMessagesStore } from '@/stores/useMessagesStore';
import { ManageBlog } from '@/components/shared/usermenu/ManageBlog/ManageBlog';
import { PawPrint } from 'lucide-react';

interface UserMenuProps {
  user: User;
}

export function UserMenu({ user }: UserMenuProps) {
  const { messages, fetchMessages } = useMessagesStore();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isManageBlogOpen, setIsManageBlogOpen] = useState(false);

  useEffect(() => {
    if (user) {
      fetchMessages(user.id, user.token);
    }
  }, [user, fetchMessages]);

  const totalNotifications = messages.length;
  const clearSession = useClearSession();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className='relative h-8 w-8 rounded-full border border-gray-600 text-gray-900'
            size='icon'
            variant='outline'
          >
            {totalNotifications > 0 && (
              <Badge
                className='absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform'
                variant='destructive'
              >
                {totalNotifications}
              </Badge>
            )}
            <PawPrint className='absolute inset-0 m-auto' size={24} />
            <span className='sr-only'>Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end' className=''>
          <DropdownMenuItem>
            <Link href={'/dashboard'}>Dashboard</Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <MessagesDrawer messages={messages} />
            {totalNotifications > 0 && (
              <Badge className='ml-2' variant='default'>
                {totalNotifications}
              </Badge>
            )}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsSettingsOpen(true)}>Settings</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={clearSession}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ManageBlog open={isManageBlogOpen} onOpenChange={setIsManageBlogOpen} />
      <SettingsPanel open={isSettingsOpen} onOpenChange={setIsSettingsOpen} />
    </>
  );
}
