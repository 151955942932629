// stores/useMessagesStore.ts
import { create } from 'zustand';
import { Message } from '@/types/Message';

interface MessagesStore {
  messages: Message[];
  fetchMessages: (userId: string, token: string) => void;
  setMessages: (messages: Message[]) => void;
  archiveMessage: (id: string, userId: string, token: string) => void;
}

export const useMessagesStore = create<MessagesStore>((set) => ({
  messages: [],
  fetchMessages: async (userId: string, token: string) => {
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/messages/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        set({ messages: data });
      } else {
        console.error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  },
  setMessages: (messages) => set({ messages }),
  archiveMessage: async (id: string, userId: string, token: string) => {
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/messages/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, isArchived: true }),
      });

      if (response.ok) {
        set((state) => ({
          messages: state.messages.map((msg) => (msg.id === id ? { ...msg, isArchived: true } : msg)),
        }));
      } else {
        console.error('Failed to archive message');
      }
    } catch (error) {
      console.error('Error archiving message:', error);
    }
  },
}));
