import React from 'react';
import { Message } from '@/types/Message';
import { MessageItem } from './MessageItem';

interface MessageListProps {
  messages: Message[];
  handleArchive: (id: string) => void;
  handleCreateClient: (email: string) => void;
}

export const MessageList: React.FC<MessageListProps> = ({ messages, handleArchive, handleCreateClient }) => {
  return (
    <div>
      {messages.length === 0 ? (
        <div className='px-6 py-4 text-center text-gray-500'>No messages to display</div>
      ) : (
        messages.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            handleArchive={handleArchive}
            handleCreateClient={handleCreateClient}
          />
        ))
      )}
    </div>
  );
};
