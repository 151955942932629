import React from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { PostTable } from './PostTable';
import { PostForm } from './PostForm';
import { Post } from '@/types/Post';

interface PostListProps {
  posts: Post[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  handleCreatePost: (post: Post) => void;
  handleEditPost: (post: Post) => void;
  handleUpdatePost: (post: Post) => void;
  newPost: Post;
  editingPost: Post | null;
}

export const PostList: React.FC<PostListProps> = ({
  posts,
  activeTab,
  setActiveTab,
  handleCreatePost,
  handleEditPost,
  handleUpdatePost,
  newPost,
  editingPost,
}) => {
  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList>
        <TabsTrigger value='posts'>Posts</TabsTrigger>
        <TabsTrigger value='create'>Create Post</TabsTrigger>
      </TabsList>
      <TabsContent value='posts'>
        <PostTable posts={posts} handleEditPost={handleEditPost} />
      </TabsContent>
      <TabsContent value='create'>
        <PostForm initialData={newPost} onSave={handleCreatePost} />
      </TabsContent>
      <TabsContent value='edit'>
        {editingPost && <PostForm initialData={editingPost} onSave={handleUpdatePost} />}
      </TabsContent>
    </Tabs>
  );
};
