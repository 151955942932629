import React, { useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Post } from '@/types/Post';

interface PostFormProps {
  initialData: Post;
  onSave: (data: Post) => void;
}

export const PostForm: React.FC<PostFormProps> = ({ initialData, onSave }) => {
  const [post, setPost] = useState(initialData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setPost((prevPost) => ({ ...prevPost, [id]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPost((prevPost) => ({ ...prevPost, image: e.target.files[0] }));
  };

  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <Label htmlFor='title'>Title</Label>
        <Input id='title' value={post.title} onChange={handleInputChange} />
      </div>
      <div className='space-y-2'>
        <Label htmlFor='content'>Content</Label>
        <Textarea id='content' value={post.content} onChange={handleInputChange} className='min-h-[300px]' />
      </div>
      <div className='space-y-2'>
        <Label htmlFor='image'>Image</Label>
        <Input id='image' type='file' onChange={handleFileChange} />
      </div>
      <Button onClick={() => onSave(post)}>Save Post</Button>
    </div>
  );
};
