import React, { useState } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from '@/components/ui/drawer';
import { useMessagesStore } from '@/stores/useMessagesStore';
import useClientStore from '@/stores/useClientStore';
import { useSession } from 'next-auth/react';
import { useToast } from '@/components/ui/use-toast';
import { Message } from '@/types/Message';
import { MessageList } from './MessageList';
import { ArchiveAllButton } from './ArchiveAllButton';

interface MessagesDrawerProps {
  messages: Message[];
}

export function MessagesDrawer({ messages }: MessagesDrawerProps) {
  const archiveMessage = useMessagesStore((state) => state.archiveMessage);
  const [isArchivingMessages, setIsArchivingMessages] = useState(false);
  const { fetchMessages } = useMessagesStore();
  const { data: session } = useSession();
  const user = session?.user;

  const createClient = useClientStore((state) => state.createClient);
  const fetchClients = useClientStore((state) => state.fetchClients);
  const { toast } = useToast();

  const handleArchive = async (id: string) => {
    if (user) {
      await archiveMessage(id, user.id, user.token);
      fetchMessages(user.id, user.token);
    }
  };

  const handleArchiveAll = async () => {
    setIsArchivingMessages(true);
    if (user) {
      for (const message of messages) {
        await archiveMessage(message.id, user.id, user.token);
      }
      fetchMessages(user.id, user.token);
    }
    setIsArchivingMessages(false);
  };

  const handleCreateClient = async (email: string) => {
    if (user) {
      try {
        await createClient('New Client', email, '123-456-7890', 'Labrador', user.id, user.token);
        await fetchClients(user.token);
        toast({
          title: 'Success',
          description: 'Client created successfully',
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to create client',
          variant: 'destructive',
        });
      }
    }
  };

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <button className='text-gray-700 hover:text-gray-900 focus:outline-none'>Messages</button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerDescription className='flex justify-end'>
            <ArchiveAllButton
              handleArchiveAll={handleArchiveAll}
              disabled={messages.length === 0}
              loading={isArchivingMessages}
            />
          </DrawerDescription>
        </DrawerHeader>
        <MessageList messages={messages} handleArchive={handleArchive} handleCreateClient={handleCreateClient} />
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
}
