'use client';

import { create } from 'zustand';
import axios, { AxiosError } from 'axios';
import { ClientStore } from '@/types/ClientStore';

const API_URL = 'https://api.otter-labs.com/api/clients';

const retryRequest = async (requestFn: () => Promise<any>, maxRetries: number, delay: number = 500) => {
  let attempts = 0;
  while (attempts < maxRetries) {
    try {
      return await requestFn();
    } catch (error) {
      attempts++;
      if (attempts >= maxRetries) {
        throw error;
      }
      console.warn(`Retrying request... (${attempts}/${maxRetries})`);
      await new Promise((resolve) => setTimeout(resolve, delay * Math.pow(2, attempts - 1)));
    }
  }
};

const useClientStore = create<ClientStore>((set) => ({
  clients: [],
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  error: null,

  fetchClients: async (token: string) => {
    set({ isLoadingFetch: true, error: null });
    try {
      const response = await retryRequest(
        () =>
          axios.get(API_URL, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        3,
      );
      set({ clients: response.data, isLoadingFetch: false });
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to fetch clients:', axiosError.message);
      set({ isLoadingFetch: false, error: axiosError.message });
    }
  },

  createClient: async (name: string, email: string, phone: string, dogBreed: string, owner: string, token: string) => {
    set({ isLoadingCreate: true, error: null });
    try {
      const response = await retryRequest(
        () =>
          axios.post(
            API_URL,
            { name, email, phone, dogBreed, owner },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        3,
      );
      set((state) => ({
        clients: [...state.clients, response.data],
        isLoadingCreate: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to create client:', axiosError.message);
      set({ isLoadingCreate: false, error: axiosError.message });
    }
  },

  updateClient: async (
    id: string,
    name: string,
    email: string,
    avatar: string,
    owner: string,
    dogBreed: string,
    packages: Array<string>,
    token: string,
  ) => {
    set({ isLoadingUpdate: true, error: null });
    try {
      const response = await retryRequest(
        () =>
          axios.put(
            API_URL,
            { id, name, email, avatar, owner, dogBreed, packages },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        3,
      );
      set((state) => ({
        clients: state.clients.map((client) => (client.id === id ? response.data : client)),
        isLoadingUpdate: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to update client:', axiosError.message);
      set({ isLoadingUpdate: false, error: axiosError.message });
    }
  },

  deleteClient: async (id: string, token: string) => {
    set({ isLoadingDelete: true, error: null });
    try {
      await retryRequest(
        () =>
          axios.delete(`${API_URL}?id=${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        3,
      );
      set((state) => ({
        clients: state.clients.filter((client) => client.id !== id),
        isLoadingDelete: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to delete client:', axiosError.message);
      set({ isLoadingDelete: false, error: axiosError.message });
    }
  },
}));

export default useClientStore;
