import React from 'react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

interface PublicToggleProps {
  isPublic: boolean;
  onToggle: () => void;
}

export const PublicToggle: React.FC<PublicToggleProps> = ({ isPublic, onToggle }) => {
  return (
    <div className='mb-4 flex items-center space-x-2'>
      <Switch id='isPublic' checked={isPublic} onCheckedChange={onToggle} />
      <Label htmlFor='isPublic'>Public Viewing</Label>
    </div>
  );
};
