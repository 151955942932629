'use client';

import { create } from 'zustand';
import axios, { AxiosError } from 'axios';
import { BlogPostStore } from '@/types/BlogPost';
import { Post } from '@/types/Post';

const API_URL = 'https://api.otter-labs.com/api/blog';

const useBlogPostStore = create<BlogPostStore>((set) => ({
  blogPosts: [],
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  error: null,

  fetchBlogPosts: async (token: string) => {
    set({ isLoadingFetch: true, error: null });
    try {
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      set({ blogPosts: response.data, isLoadingFetch: false });
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to fetch blog posts:', axiosError.message);
      set({ isLoadingFetch: false, error: axiosError.message });
    }
  },

  createBlogPost: async (title, headerImage, article, isPublic, token) => {
    set({ isLoadingCreate: true, error: null });
    try {
      const response = await axios.post(
        API_URL,
        { title, headerImage, article, isPublic },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      set((state) => ({
        blogPosts: [...state.blogPosts, response.data],
        isLoadingCreate: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to create blog post:', axiosError.message);
      set({ isLoadingCreate: false, error: axiosError.message });
    }
  },

  updateBlogPost: async (id, title, headerImage, article, isPublic, token) => {
    set({ isLoadingUpdate: true, error: null });
    try {
      const response = await axios.put(
        `${API_URL}/${id}`,
        { title, headerImage, article, isPublic },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      set((state) => ({
        blogPosts: state.blogPosts.map((blogPost) => (blogPost.id === id ? response.data : blogPost)),
        isLoadingUpdate: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to update blog post:', axiosError.message);
      set({ isLoadingUpdate: false, error: axiosError.message });
    }
  },

  deleteBlogPost: async (id, token) => {
    set({ isLoadingDelete: true, error: null });
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      set((state) => ({
        blogPosts: state.blogPosts.filter((blogPost) => blogPost.id !== id),
        isLoadingDelete: false,
      }));
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Failed to delete blog post:', axiosError.message);
      set({ isLoadingDelete: false, error: axiosError.message });
    }
  },
}));

export default useBlogPostStore;
