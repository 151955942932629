'use client';

import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { PostList } from './PostList';
import { Post } from '@/types/Post';
import useBlogPostStore from '@/stores/useBlogPostStore';
import { useSession } from 'next-auth/react';

export function ManageBlog({ open, onOpenChange }) {
  const { data: session } = useSession();
  const user = session?.user;
  const token = user?.token;
  const [activeTab, setActiveTab] = useState('posts');
  const { blogPosts, fetchBlogPosts, createBlogPost, updateBlogPost } = useBlogPostStore();

  useEffect(() => {
    if (token) {
      fetchBlogPosts(token);
    }
  }, [token, fetchBlogPosts]);

  const [newPost, setNewPost] = useState<Post>({
    id: '',
    title: '',
    content: '',
    userId: '',
    date: '',
    status: '',
    headerImage: '',
    isPublic: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  const [editingPost, setEditingPost] = useState<Post | null>(null);

  const handleCreatePost = (post: Post) => {
    createBlogPost(post.title, post.headerImage, post.content, false, token);
    setNewPost({
      id: '',
      title: '',
      content: '',
      userId: '',
      date: '',
      status: '',
      headerImage: '',
      isPublic: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    setActiveTab('posts');
  };

  const handleEditPost = (post: Post) => {
    setEditingPost(post);
    setActiveTab('edit');
  };

  const handleUpdatePost = (updatedPost: Post) => {
    updateBlogPost(updatedPost.id, updatedPost.title, updatedPost.headerImage, updatedPost.content, false, token);
    setEditingPost(null);
    setActiveTab('posts');
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Blog Management</DialogTitle>
          <DialogDescription>Manage your blog posts and create new content.</DialogDescription>
        </DialogHeader>
        <PostList
          posts={blogPosts}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleCreatePost={handleCreatePost}
          handleEditPost={handleEditPost}
          handleUpdatePost={handleUpdatePost}
          newPost={newPost}
          editingPost={editingPost}
        />
      </DialogContent>
    </Dialog>
  );
}
