import React from 'react';
import { TableRow, TableCell } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { FilePenIcon } from '@/components/icons';
import { Post } from '@/types/Post';

interface PostTableRowProps {
  post: Post;
  handleEditPost: (post: Post) => void;
}

export const PostTableRow: React.FC<PostTableRowProps> = ({ post, handleEditPost }) => {
  return (
    <TableRow>
      <TableCell>{post.title}</TableCell>
      <TableCell>{post.date}</TableCell>
      <TableCell>
        <Badge variant={post.status === 'published' ? 'default' : 'outline'}>{post.status}</Badge>
      </TableCell>
      <TableCell>
        <Button variant='ghost' size='icon' onClick={() => handleEditPost(post)}>
          <FilePenIcon className='h-4 w-4' />
          <span className='sr-only'>Edit post</span>
        </Button>
      </TableCell>
    </TableRow>
  );
};
