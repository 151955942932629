/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import usePackageStore from '@/stores/usePackageStore';
import { useSession } from 'next-auth/react';
import { PublicToggle } from './PublicToggle';
import { BannerText } from './BannerText';
import { TrainerDescription } from './TrainerDescription';
import { ServiceAreaZipcode } from './ServiceAreaZipcode';
import { PackageToggles } from './PackageToggles';
import { HeaderImageUpload } from './HeaderImageUpload';
import { SaveButton } from './SaveButton';
import { ToastNotification } from './ToastNotification';
import { Button } from '@/components/ui/button';

export function PublicPageSettings() {
  const { packages, fetchPackages, updatePackages } = usePackageStore();
  const [headerImage, setHeaderImage] = useState<string | null>(null);
  const [headerName, setHeaderName] = useState('');
  const [trainerDescription, setTrainerDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [localPackages, setLocalPackages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const { data: session } = useSession();
  const user = session?.user;

  useEffect(() => {
    if (user?.token) {
      fetchPackages(user.token);
      fetchTrainerSettings(user.token);
    }
  }, []);

  useEffect(() => {
    setLocalPackages(packages);
  }, [packages]);

  const fetchTrainerSettings = async (token: string) => {
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/${user?.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTrainerDescription(data.publicPage.trainerDescription);
      setIsPublic(data.publicPage.isPublic);
      setHeaderName(data.publicPage.headerName || ''); // Ensure headerName is updated
      setZipCode(data.publicPage.zipCodeServiceArea || '');
    } catch (error) {
      console.error('Error fetching trainer settings', error);
    }
  };

  const handleTrainerDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTrainerDescription(e.target.value);
  };

  const handlePackageToggle = (packageId: string) => {
    setLocalPackages((prevPackages) =>
      prevPackages.map((pkg) => (pkg.id === packageId ? { ...pkg, isPublic: !pkg.isPublic } : pkg)),
    );
  };

  const handlePublicToggle = () => {
    setIsPublic((prevIsPublic) => !prevIsPublic);
  };

  const handleZipCodeChange = (zip: string) => {
    setZipCode(zip);
  };

  const handleHeaderNameChange = (name: string) => {
    setHeaderName(name);
  };

  const handleSaveClick = async () => {
    setRequestInProgress(true);
    try {
      const response = await fetch(`https://api.otter-labs.com/api/publicPage/${user?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          headerImage,
          headerName,
          trainerDescription,
          packages: localPackages.filter((pkg) => pkg.type === 'enabled').map((pkg) => pkg.id),
          isPublic,
          zipCode,
        }),
      });

      if (response.ok) {
        await updatePackages(localPackages, user?.token);
        await fetchPackages(user.token);
        setShowToast(true);
      } else {
        console.error('Error updating public page');
      }
    } catch (error) {
      console.error('Error updating public page', error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const publicPageUrl = `https://barkontrack.com/${user?.id}`;

  return (
    <div className='p-6 text-black'>
      <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Public Visibility</h2>
          <PublicToggle isPublic={isPublic} onToggle={handlePublicToggle} />
        </div>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Banner Text</h2>
          <BannerText headerName={headerName} onHeaderNameChange={handleHeaderNameChange} />
        </div>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Trainer Description</h2>
          <TrainerDescription description={trainerDescription} onDescriptionChange={handleTrainerDescriptionChange} />
        </div>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Service Area Zipcode</h2>
          <ServiceAreaZipcode zipCode={zipCode} onZipChange={handleZipCodeChange} />
        </div>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Package Toggles</h2>
          <PackageToggles packages={localPackages} onToggle={handlePackageToggle} />
        </div>
        <div className='rounded-lg bg-gray-100 p-4'>
          <h2 className='mb-2 text-lg font-semibold'>Header Image Upload</h2>
          <HeaderImageUpload
            onImageUploadComplete={(res) => setHeaderImage(res[0].serverData.file)}
            onUploadError={(error) => alert(`ERROR! ${error.message}`)}
          />
        </div>
      </div>
      <div className='mt-4 flex justify-end sm:px-6'>
        <SaveButton onSave={handleSaveClick} isLoading={requestInProgress} />
        <a href={publicPageUrl} target='_blank' rel='noopener noreferrer' className='ml-4'>
          <Button>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
              <path d='M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z' />
              <path d='M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z' />
            </svg>
          </Button>
        </a>
      </div>
    </div>
  );
}

export default PublicPageSettings;
